<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            :items="customers"
            item-text="name"
            item-value="id"
            v-model="params.customerId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-select
            outlined
            flat
            dense
            background-color="white"
            :items="statuses"
            v-model="params.status"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-btn @click="search" color="primary">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Invoice #</th>
              <th class="text-center">% Invoice</th>
              <th class="text-center">Date</th>
              <th class="text-center">PO#</th>
              <th class="text-center">Sales</th>
              <th class="text-center">Customer Name</th>
              <th class="text-center">Due Date</th>
              <th class="text-center">No</th>
              <th class="text-center">Mfr</th>
              <th class="text-center">Part #</th>
              <th class="text-center">Part Description</th>
              <th class="text-center">Qty</th>
              <th class="text-center">U/M</th>
              <th class="text-center">Curr</th>
              <th class="text-center">Unit Price</th>
              <th class="text-center">PPN (in IDR)</th>
              <th class="text-center">Payment</th>
              <th class="text-center">Balance</th>
              <th class="text-center">Age fr Due</th>
              <th class="text-center">Remark</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in arDetail.items" :key="item">
              <td>{{ item.invoiceNumber }}</td>
              <td>{{ item.percentage }}</td>
              <td>{{ item.invoiceDate }}</td>
              <td>{{ item.poNumber }}</td>
              <td>{{ item.sales }}</td>
              <td>{{ item.customerName }}</td>
              <td>{{ item.invoiceDueDate }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ item.balance }}</td>
              <td>{{ item.ageForDue }}</td>
              <td>{{ item.remark }}</td>
              <td>{{ item.status }}</td>
            </tr>
            <tr>
              <td colspan="17" class="text-right">Total Invoice {{ arDetail.customerName }}</td>
              <td>{{ arDetail.total }}</td>
            </tr>
            <tr>
              <td colspan="17" class="text-right">Total A/R: {{ arDetail.customerName }}</td>
              <td>{{ arDetail.total }}</td>
            </tr>
            <tr>
              <td colspan="17" class="text-right">Total A/R</td>
              <td>{{ arDetail.total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "report-ar-detail",
  data() {
    return {
      menuStartDate: false,
      params: {
        customerId: null,
        date: moment().format("yyyy-MM-DD"),
        status: "all",
      },
      statuses: ["all", "outstanding", "paid"],
    };
  },
  computed: {
    ...mapState("external", ["customers"]),
    ...mapState("report", ["arDetail"]),
  },
  methods: {
    search() {
      this.$store.dispatch("report/arDetail", this.params);
    },
  },
  mounted() {
    this.$store.dispatch("external/geCustomers");
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
